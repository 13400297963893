var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"md":"12"}},[_c('h2',{staticClass:"mb-1 mb-lg-0"},[_vm._v(" Аналитика ")])]),_c('b-col',{staticClass:"mb-1 mb-lg-0",attrs:{"cols":"12","lg":"2","md":"6"}},[_c('common-date-picker',_vm._b({on:{"context":_vm.onDateFrom}},'common-date-picker',{
                value: _vm.date_from,
              },false))],1),_c('b-col',{staticClass:"mb-1 mb-lg-0",attrs:{"cols":"12","lg":"2","md":"6"}},[_c('common-date-picker',_vm._b({on:{"context":_vm.onDateTo}},'common-date-picker',{
                value: _vm.date_to,
              },false))],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',[_c('h4',{staticClass:"text-center"},[_vm._v(" Общая сумма всех стоимостей ")]),(Object.keys(_vm.costDiagram).length)?[_c('analytic-pie',_vm._b({},'analytic-pie',{
            type: 'cost',
            items: _vm.costDiagram
          },false))]:[_c('b-card-text',{staticClass:"text-center"},[_vm._v(" Нет данных ")])]],2)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',{staticClass:"text-center"},[_c('h4',[_vm._v(" Количество заказов по каждому из источников ")]),(Object.keys(_vm.deliveryDiagram).length)?[_c('analytic-pie',_vm._b({},'analytic-pie',{
            type: 'delivery',
            items: _vm.deliveryDiagram
          },false))]:[_c('b-card-text',{staticClass:"text-center"},[_vm._v(" Нет данных ")])]],2)],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-card',[(Object.keys(_vm.graph).length)?[_c('analytic-line',_vm._b({},'analytic-line',{
            items: _vm.graph,
            date_from: _vm.date_from,
            date_to: _vm.date_to,
            data_source_loading: _vm.data_source_loading,
        },false))]:[_c('b-card-text',{staticClass:"text-center"},[_vm._v(" Нет данных ")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <b-row>
      <b-col cols=12>
        <b-card>
          <b-row class="justify-content-end">
            <b-col md=12>
              <h2 class="mb-1 mb-lg-0"> Аналитика </h2>
            </b-col>
            <b-col
              cols="12"
              lg='2'
              md="6"
              class="mb-1 mb-lg-0"
            >
              <common-date-picker
                v-bind="{
                  value: date_from,
                }"
                @context="onDateFrom"
              />
            </b-col>
            <b-col
              cols="12"
              lg='2'
              md="6"
              class="mb-1 mb-lg-0"
            >
              <common-date-picker
                v-bind="{
                  value: date_to,
                }"
                @context="onDateTo"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols=12
        lg="6"
      >
        <b-card>
          <h4 class="text-center"> Общая сумма всех стоимостей </h4>
          <template v-if="Object.keys(costDiagram).length">
            <analytic-pie v-bind="{
              type: 'cost',
              items: costDiagram
            }" />
          </template>
          <template v-else>
            <b-card-text class="text-center"> Нет данных </b-card-text>
          </template>
        </b-card>
      </b-col>
      <b-col
        cols=12
        lg="6"
      >
        <b-card class="text-center">
          <h4> Количество заказов по каждому из источников </h4>
          <template v-if="Object.keys(deliveryDiagram).length">
            <analytic-pie v-bind="{
              type: 'delivery',
              items: deliveryDiagram
            }" />
          </template>
          <template v-else>
            <b-card-text class="text-center"> Нет данных </b-card-text>
          </template>
        </b-card>
      </b-col>
      <b-col
        cols=12
        lg=12
      >
        <b-card>
          <template v-if="Object.keys(graph).length">
            <analytic-line v-bind="{
              items: graph,
              date_from,
              date_to,
              data_source_loading,
          }" />
          </template>
          <template v-else>
            <b-card-text class="text-center"> Нет данных </b-card-text>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>


<script>
import { AuthNameSpace } from '@/store/modules/auth/types'
import { AnalyticNameSpace, AnalyticActionTypes } from '@/store/modules/analytic/types'
import { mapActions, mapState } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { throttle } from 'lodash'
import { duration } from '@/config'

export default {
  components: {
    BRow,
    BCol,
    AnalyticPie: () => import('@/components/analytic/AnalyticPie.vue'),
    AnalyticLine: () => import('@/components/analytic/AnalyticLine.vue'),
    CommonDatePicker: () => import('@/components/common/common-date-picker.vue'),
  },
  data() {
    return {
      date_from: new Date(new Date().setDate(new Date().getDate() - 6)),
      date_to: new Date(),
      city_id: this.active_city,
      data_source_loading: true,
    }
  },
  computed: {
    ...mapState(AuthNameSpace, {
      active_city: 'city_id',
    }),
    ...mapState(AnalyticNameSpace, {
      costDiagram: 'costDiagram',
      deliveryDiagram: 'deliveryDiagram',
      graph: 'graph',
    }),
  },
  methods: {
    ...mapActions(AnalyticNameSpace, {
      [AnalyticActionTypes.LoadAnalyticCostDiagram]: AnalyticActionTypes.LoadAnalyticCostDiagram,
      [AnalyticActionTypes.LoadAnalyticDeliveryDiagram]: AnalyticActionTypes.LoadAnalyticDeliveryDiagram,
      [AnalyticActionTypes.LoadOrderDataSourceGraph]: AnalyticActionTypes.LoadOrderDataSourceGraph,
    }),
    loadAnalyticCostDiagram: throttle(async function() {
      await this[AnalyticActionTypes.LoadAnalyticCostDiagram]({ ...this.$data })
    }, duration),

    loadAnalyticDeliveryDiagram: throttle(async function() {
      await this[AnalyticActionTypes.LoadAnalyticDeliveryDiagram]({ ...this.$data })
    }, duration),

    loadOrderDataSourceGraph: throttle(async function() {
      this.data_source_loading = true
      await this[AnalyticActionTypes.LoadOrderDataSourceGraph]({ ...this.$data })
      this.data_source_loading = false
    }, duration),

    async onDateFrom({ selectedDate }) {
      this.date_from = new Date(selectedDate)
      await this.loadAnalyticCostDiagram()
      await this.loadAnalyticDeliveryDiagram()
      await this.loadOrderDataSourceGraph()
    },

    async onDateTo({ selectedDate }) {
      this.date_to = new Date(selectedDate)
      await this.loadAnalyticCostDiagram()
      await this.loadAnalyticDeliveryDiagram()
      await this.loadOrderDataSourceGraph()
    },

    dateDisabledFnFrom(...props) {
      return new Date(props[1]).getTime() > new Date(this.date_to).getTime()
    },

    dateDisabledFnTo(...props) {
      return new Date(props[1]).getTime() < new Date(this.date_from).getTime()
    },
  },

  async beforeMount() {
    await this.loadAnalyticCostDiagram()
    await this.loadAnalyticDeliveryDiagram()
    await this.loadOrderDataSourceGraph()
  },
  watch: {
    async active_city(city_id) {
      this.city_id = city_id
      await this.loadAnalyticCostDiagram()
      await this.loadAnalyticDeliveryDiagram()
      await this.loadOrderDataSourceGraph()
    },
  },
}
</script>
